import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://46f4e97abde94e52937e03a8c68c6707@o349930.ingest.sentry.io/4504054754443264",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 0.05,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  replaysSessionSampleRate: 0.05,
  replaysOnErrorSampleRate: 1,
});